.banner {
  background: var(--neighbor-color-1);
}
.custom-shape-divider-bottom-1634653438 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1634653438 svg {
    position: relative;
    display: block;
    width: calc(184% + 1.3px);
    height: 150px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1634653438 .shape-fill {
    fill: #FFFFFF;
}