.App {
  text-align: center;
}
:root {
  --neighbor-color-1: #010d65;
  --neighbor-color-2: #7ab4c1;
  --neighbor-color-3: #008396;
  --neighbor-color-4: #324b50;
  --neighbor-color-5: #E8D5B5;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@font-face {
  font-family: 'qweqwe';
  src: url('./assets/font/NotoSansKR-Black.otf');
}
@font-face {
  font-family: 'ewqewq';
  src: url('./assets/font/NotoSansKR-Bold.otf');
}
@font-face {
  font-family: '3';
  src: url('./assets/font/NotoSansKR-Light.otf');
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
